<app-modal-header />
<ion-content class="ion-padding">
  <main class="flex flex-col h-full">
    @if (!updated()) { @if (!verified()) {

    <section class="flex flex-col items-center gap-4">
      <h1>비밀번호 찾기</h1>
      <p>비밀번호를 찾으시려면 아래 항목을 입력해 주세요.</p>
    </section>

    <form [formGroup]="findPasswordForm" class="flex flex-col flex-1" (ngSubmit)="submit()">
      <div class="flex flex-col flex-1 gap-4">
        <app-input-email formControlName="email" label="이메일" placeholder="이메일을 입력해 주세요" />
        <app-input-text formControlName="name" label="이름" placeholder="이름을 입력해 주세요" />
        <app-input-tel formControlName="tel" label="전화번호" placeholder="전화번호를 입력해 주세요" />
      </div>

      <app-button type="submit" [disabled]="findPasswordForm.invalid">확인</app-button>
    </form>
    } @else {
    <section class="flex flex-col items-center gap-4">
      <h1>비밀번호 찾기</h1>
    </section>

    <form [formGroup]="resetPasswordForm" class="flex flex-col flex-1" (ngSubmit)="setPassword()">
      <div class="flex flex-col flex-1 gap-4">
        <app-input-password formControlName="password" label="비밀번호" placeholder="비밀번호를 입력해 주세요" />
        <app-input-password formControlName="passwordConfirm" label="비밀번호 확인" placeholder="비밀번호를 다시 한 번 입력해 주세요" />
      </div>

      <app-button expand type="submit" [disabled]="resetPasswordForm.invalid">확인</app-button>
    </form>
    } } @else {
    <div class="flex flex-col h-full">
      <div class="flex flex-col items-center justify-center flex-1 py-6">
        <app-icon name="checkmark-circle" class="size-16 text-primary" />
        <p>비밀번호가 변경되었습니다.</p>
      </div>

      <app-button expand (click)="modal.dismiss()">확인</app-button>
    </div>
    }
  </main>
</ion-content>
