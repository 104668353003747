<app-modal-header buttons="false" />
<ion-content class="ion-padding">
  <main class="flex flex-col gap-4">
    <section class="flex flex-col items-center gap-4">
      <h1>회원가입</h1>
      <p>회원가입을 위해 정보를 입력해주세요.</p>
    </section>
    <form [formGroup]="form" class="flex flex-col flex-1 gap-4" (ngSubmit)="submit()">
      <app-input-text label="이름" formControlName="name" placeholder="이름을 입력해주세요." />
      <app-input-email label="이메일" formControlName="email" placeholder="이메일을 입력해주세요." />
      <app-input-password label="비밀번호" formControlName="password" placeholder="비밀번호를 입력해주세요." />
      <app-input-password label="비밀번호 확인" formControlName="passwordConfirm" placeholder="비밀번호를 다시 한 번 입력해주세요." />
      <app-input-tel validate label="전화번호" formControlName="tel" placeholder="전화번호를 입력해주세요." />
    </form>
  </main>
</ion-content>
<section class="p-4">
  <app-button expand type="submit" [disabled]="form.invalid">회원가입</app-button>
</section>
