<app-modal-header buttons="false" />
<ion-content class="ion-padding">
  <div class="flex flex-col justify-between h-full py-16">
    <div class="flex items-center justify-center flex-1">
      <h1 class="font-gmarket text-primary">오늘의 삼천포</h1>
    </div>
    <form [formGroup]="form" class="flex flex-col gap-4" (ngSubmit)="submit()">
      <app-input-email label="이메일" formControlName="email" />
      <app-input-password label="비밀번호" formControlName="password" />
      <app-button expand type="submit">로그인</app-button>

      <div class="grid grid-cols-3 divide-x divide-neutral-100">
        <a class="flex justify-center text-sm font-extrabold text-neutral-800" (click)="openSignupModal()">회원가입</a>
        <a class="flex justify-center text-sm font-extrabold text-neutral-800" (click)="openFindEmailModal()">이메일 찾기</a>
        <a class="flex justify-center text-sm font-extrabold text-neutral-800" (click)="openFindPasswordModal()">비밀번호 찾기</a>
      </div>

      <div class="flex items-center gap-2">
        <div class="flex-1 bg-neutral-100 h-0.5 mx-1"></div>
        <p class="text-sm font-semibold text-neutral-500">SNS로 간편하게 로그인</p>
        <div class="flex-1 bg-neutral-100 h-0.5 mx-1"></div>
      </div>

      <button
        type="button"
        style="background: #fee500"
        class="relative flex items-center justify-center py-3 rounded-lg"
        (click)="openKakaoLogin()"
      >
        <img src="/icons/kakao.svg" class="absolute size-6 left-3 text-neutral-900" />
        <span> 카카오로 계속하기</span>
      </button>

      @if (isIOS) {
      <button
        type="button"
        class="relative flex items-center justify-center py-3 bg-white border border-solid rounded-lg border-neutral-400"
        (click)="appleLogin()"
      >
        <app-icon name="ion:logo-apple" class="absolute size-6 left-3 text-neutral-900" />
        <span> 애플 ID로 계속하기</span>
      </button>
      }

      <p class="text-center text-neutral-500">로그인하시면 아래 내용에 동의하는 것으로 간주됩니다.</p>

      <div class="flex items-center justify-center gap-6">
        <a class="underline text-neutral-500" (click)="openEULAModal()">서비스이용약관</a>
        <a class="underline text-neutral-500" (click)="openPrivacyPolicyModal()">개인정보처리방침</a>
      </div>
    </form>
  </div>
</ion-content>
